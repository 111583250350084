import { useFetcher, useFetchers, useLocation, useMatches } from "@remix-run/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { v4 as uuid } from "uuid";
import { deleteCooking, deleteCurrentCooking, getCooking, getCurrentCooking, setCooking, setCurrentCooking } from "~/utils/app/page-restoration";
import { useAppTenantRoute } from "~/utils/data/useRecipeRouteData";
import { useRootData } from "~/utils/data/useRootData";
import supabase from "~/utils/integrations/supabaseClientService";
import { usePopoverSelectorContext } from "./popoverContext";

const CookRecipeContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useCookRecipeContextVals = () => {
  const rootData = useRootData();
  const apptenantData = useAppTenantRoute();
  const [open, setOpen] = React.useState(false);
  const [chooseOpen, setChooseOpen] = React.useState(false);
  const [ingredientsOpen, setIngredientsOpen] = React.useState(false);
  const [currentRecipeId, setCurrentRecipeId] = React.useState(getCurrentCooking());
  const [thisRecipeId, setThisRecipeId] = React.useState(null);
  const [thisCollectionId, setThisCollectionId] = React.useState(null);
  // const [recipes, setRecipes] = React.useState(apptenantData?.data?.recipes ?? []);
  const [recipes, setRecipes] = React.useState([]);
  const [recipeCurrentSteps, setRecipeCurrentSteps] = React.useState(apptenantData?.data?.progress ?? []);
  const [ingredientsTab, setIngredientsTab] = React.useState("prepping");
  const [ingredientsPrepped, setIngredientsPrepped] = React.useState({});
  const [ingredientsChecked, setIngredientsChecked] = React.useState({});
  const [initialized, setInitialized] = React.useState(false);
  const [currentRecipeServings, setCurrentRecipeServings] = React.useState(1);
  const [currentServingRatio, setCurrentServingRatio] = React.useState(1);
  const recipeLookupFetcher = useFetcher();
  const recipeFetcher = useFetcher();
  const progressFetcher = useFetcher();
  const localStorageFetcher = useFetcher();
  const ingredientFetcher = useFetcher();
  const collectionFetcher = useFetcher();
  const rCSRef = useRef(recipeCurrentSteps);
  rCSRef.current = recipeCurrentSteps;
  const fetchers = useFetchers();
  const matches = useMatches();
  const location = useLocation();
  const subscriptionRef = useRef();
  const locationRef = useRef();
  const openSide = usePopoverSelectorContext((state) => state.openSide);
  const closeSide = usePopoverSelectorContext((state) => state.closeSide);
  const sidesOpen = usePopoverSelectorContext((state) => state.sidesOpen);
  const recipeFetcher2 = useFetcher();

  useEffect(() => {
    // need to set the recipes to the data from the fetcher, but only if the fetcher has data
    // remove duplicates based on id
    if (!recipeFetcher2.data) return;
    if (!Array.isArray(recipeFetcher2.data.recipes)) return;

    setRecipes((prev) =>
      [...prev, ...recipeFetcher2.data.recipes].reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
    );
  }, [recipeFetcher2.data]);

  useEffect(() => {
    // on mount, need to get the recipes for the user if the user is logged in
    if (rootData.hasSub) {
      const form = new FormData();
      form.set("action", "getRecipes");
      recipeFetcher2.submit(form, { method: "POST", action: "/api/recipe/lookup" });
    }
  }, [rootData]);

  const recipeWithStep = useMemo(
    () =>
      recipeCurrentSteps
        ?.map((r) => {
          const recipe = recipes.find((r2) => r2.id === r.recipeId);
          if (recipe) {
            return { ...r, ...recipe };
          }
          return null;
        })
        ?.filter((r) => r !== null),
    [recipeCurrentSteps, recipes]
  );

  // useEffect(() => {
  //   if (rootData.recipes) {
  //     setRecipes((oldRecipes) => [...oldRecipes, ...recipes]);
  //   }
  // }, [rootData.recipes]);

  useEffect(() => {
    if (sidesOpen.includes("cook")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (sidesOpen.includes("choose")) {
      setChooseOpen(true);
    } else {
      setChooseOpen(false);
    }
    if (sidesOpen.includes("ingredients")) {
      setIngredientsOpen(true);
    } else {
      setIngredientsOpen(false);
    }
  }, [sidesOpen]);

  locationRef.current = location;

  const replaceRecipe = useCallback(
    (recipe) => {
      // console.log("replaceRecipe", recipe);
      setRecipes((prev) => {
        const newRecipes = prev.map((r) => {
          if (r.id === recipe.id) {
            return recipe;
          }
          return r;
        });
        const found = newRecipes.find((r) => r.id === recipe.id);
        if (!found) {
          newRecipes.push(recipe);
        }
        return newRecipes;
      });
    },
    [recipes]
  );

  //create effect to watch for collectionFetcher data changes
  useEffect(() => {
    if (collectionFetcher.data) {
      setRecipes((prev) => {
        // console.log("getRecipeCollections", collectionFetcher.data);
        return prev.map((r) => {
          if (r.id === collectionFetcher.data.recipeId) {
            return { ...r, collections: collectionFetcher.data.collectionIds, userCollections: collectionFetcher.data.userCollections };
          }
          return r;
        });
      });
    }
  }, [collectionFetcher.data]);

  const getRecipeCollections = useCallback(() => {
    const form = new FormData();
    form.set("recipeId", thisRecipeId);
    form.set("action", "getRecipeCollections");
    collectionFetcher.submit(form, { method: "POST", action: "/api/recipe/collection" });
  }, [thisRecipeId]);

  const processRecipeChange = (data) => {
    //if this is an insert, need to add the collection to the recipe collections attribute
    if (data.eventType === "INSERT") {
      setRecipes((prev) => {
        const newRecipes = prev.map((r) => {
          if (r.id === data.new.recipeId) {
            return {
              ...r,
              collections: [...r.collections, data.new.collectionId],
            };
          }
          return r;
        });
        return newRecipes;
      });
    } else if (data.eventType === "DELETE") {
      getRecipeCollections(thisRecipeId);
    }
  };

  //create an effect that creates a subscription when thisrecipeid changes
  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }
    if (thisRecipeId) {
      subscriptionRef.current = supabase
        .channel(`recipe-${thisRecipeId}`)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "RecipeCollectionItems",
            filter: `recipeId=eq.${thisRecipeId}`,
          },
          processRecipeChange
        )
        .subscribe();
    }
  }, [thisRecipeId]);

  useEffect(() => {
    if (fetchers.find((f) => f.formAction === "/api/recipe/notes")) {
      // console.log("fetchers:", fetchers);
      const notes = fetchers.find((f) => f.formAction === "/api/recipe/notes");
      if (notes?.formData) {
        const formObj = Object.fromEntries(notes.formData);
        const recipeId = formObj.recipeId;
        const recipe = recipes.find((r) => r.id === recipeId);
        if (recipe) {
          const recipeNotes = recipe.steps.map((n) => {
            if (n.id === formObj.id) {
              return {
                ...n,
                note: formObj.note,
              };
            }
            return n;
          });
          const newRecipe = { ...recipe, steps: recipeNotes };
          replaceRecipe(newRecipe);
        }
      }
    }
  }, [fetchers]);

  // const getRecipeData = (recipeId: string) => {
  //   const form = new FormData();
  //   form.set("recipeId", recipeId);
  //   recipeFetcher.submit(form, { method: "POST", action: "/api/recipe/lookup2" });
  // };

  // useEffect(() => {
  //   console.log("navigation:", navigation);
  // }, [navigation]);

  // useEffect(() => {
  //   if (params.urlId && params.urlKey) {
  //     getData(params.urlId, params.urlKey);
  //   }
  // }, [params.urlId, params.urlKey]);

  useEffect(() => {
    if (recipeFetcher.data?.recipe) {
      replaceRecipe(recipeFetcher.data.recipe);
    }
  }, [recipeFetcher.data]);

  useEffect(() => {
    if (rootData.hasSub) {
      const form = new FormData();
      form.set("prepped", JSON.stringify(ingredientsPrepped));
      form.set("action", "preppedIngredients");
      ingredientFetcher.submit(form, { method: "POST", action: "/api/recipe/progress" });
    }
  }, [ingredientsPrepped]);

  useEffect(() => {
    if (rootData.hasSub) {
      const form = new FormData();
      form.set("used", JSON.stringify(ingredientsChecked));
      form.set("action", "usedIngredients");
      ingredientFetcher.submit(form, { method: "POST", action: "/api/recipe/progress" });
    }
  }, [ingredientsChecked]);

  const currentRecipe = useMemo(() => {
    const match = matches.find((m) => m.id === "routes/app.$tenant/recipe/$urlId.$urlKey");
    if (match) {
      return JSON.stringify(match.data?.item);
    }
    return null;
  }, [matches]);

  //when the currentRecipeServings changes, update the currentServingsRatio
  useEffect(() => {
    if (currentRecipeServings && recipe) {
      const origServings = recipe.recipeYield?.split(" ") ?? [1];
      setCurrentServingRatio(currentRecipeServings / origServings[0]);
    } else {
      setCurrentServingRatio(1);
    }
  }, [currentRecipeServings]);

  const checkCurrentRecipe = useCallback(() => {
    const match = matches.find((m) => m.id === "routes/app.$tenant/recipe/$urlId.$urlKey");
    if (match) {
      const recipeId = match.data.item.id;
      const recipesIds = recipes.map((r) => r.id);
      if (recipesIds.includes(recipeId)) {
        // replaceRecipe(match.data.item);
      }
    }
  }, [matches, recipes, replaceRecipe]);

  useEffect(() => {
    currentRecipe && checkCurrentRecipe();
  }, [currentRecipe]);

  const addRecipe = useCallback(
    (recipe) => {
      if (recipes.find((r) => r.id === recipe?.id)) {
        return;
      }
      setRecipes((prev) => [...prev, recipe]);
    },
    [recipes]
  );

  useEffect(() => {
    if (ingredientFetcher.data && (ingredientFetcher.data.prepped || ingredientFetcher.data.used)) {
      const prepped = ingredientFetcher.data.prepped;
      const used = ingredientFetcher.data.used;
      if (prepped) {
        setIngredientsPrepped(prepped);
      }
      if (used) {
        setIngredientsChecked(used);
      }
    }
  }, [ingredientFetcher.data]);

  useEffect(() => {
    // when the page loads, check the local storage recipes and clean any that no longer exist
    if (rootData.hasSub) {
      const localRecipes = getCooking();
      if (localRecipes) {
        const localRecipeIds = localRecipes.map((r) => r.uuid);
        const form = new FormData();
        form.append("recipeId", localRecipeIds.join(","));
        localStorageFetcher.submit(form, { method: "POST", action: "/api/recipe/lookup" });
      }
      //getIngredients
      const uuids = rootData.progress?.map((r) => r.uuid) ?? [];
      const otherUuids = localRecipes?.map((r) => r.uuid) ?? [];
      const allUuids = [...new Set([...uuids, ...otherUuids])];
      const form = new FormData();
      form.set("action", "getIngredients");
      form.set("uuids", allUuids);
      ingredientFetcher.submit(form, { method: "POST", action: "/api/recipe/progress" });
    }
  }, []);

  useEffect(() => {
    if (localStorageFetcher.state == "idle" && localStorageFetcher.data) {
      const localRecipes = getCooking();
      //filter out the recipes that no longer exist
      // console.log("localStorageFetcher.data.currentIds", localStorageFetcher.data.currentIds);
      const newRecipes = localRecipes?.filter((r) => localStorageFetcher.data.currentIds?.includes(l.uuid));
      setCooking(newRecipes, true);
    }
  }, [localStorageFetcher?.data]);

  const removeRecipe = useCallback(
    (id) => {
      setRecipes(recipes.filter((recipe) => id !== recipe.id));
    },
    [recipes]
  );

  const recipeIds = useMemo(() => {
    return JSON.stringify(recipes.map((r) => r.id));
  }, [recipes]);

  const lookupRecipes = (ids: string[]) => {
    const form = new FormData();
    form.append("recipeId", ids.join(","));
    recipeLookupFetcher.submit(form, { method: "POST", action: "/api/recipe/lookup" });
  };

  useEffect(() => {
    setRecipeCurrentSteps(apptenantData?.data?.progress ?? []);
  }, [apptenantData?.data?.progress]);

  const checkLoaderData = useCallback(() => {
    const checkRecipeIds = JSON.stringify(recipeLookupFetcher.data.recipes?.map((r) => r.id) ?? []);
    // console.log("checkRecipeIds", checkRecipeIds);
    // console.log("recipeLookupFetcher.data.recipes", recipeLookupFetcher.data.recipes);
    if (checkRecipeIds !== recipeIds) {
      // console.log("recipeLookupFetcher.data", recipeLookupFetcher.data);
      const newRecipes = recipeLookupFetcher.data.recipes;
      setRecipes([...recipes, ...newRecipes]);
      const recipeIds = newRecipes.map((r) => r.id);
      let recipeStepCopy = recipeCurrentSteps.slice(0).filter((r) => recipeIds.includes(r.recipeId));
      deleteCooking();
      setRecipeCurrentSteps(recipeStepCopy);
    }
  }, [recipeLookupFetcher?.data, recipeIds, recipeCurrentSteps, recipes]);

  useEffect(() => {
    // console.log("recipeLookupFetcher.state", recipeLookupFetcher);
    if (recipeLookupFetcher.state == "idle" && recipeLookupFetcher.data) {
      checkLoaderData();
    }
  }, [recipeLookupFetcher.state]);

  // useEffect(() => {
  //   lookupRecipes(rootData.progress?.map((s) => s.recipeId) ?? []);
  // }, []);

  useEffect(() => {
    if (initialized) {
      if (recipeCurrentSteps.length > 0) {
        // console.log("recipeCurrentSteps: ", recipeCurrentSteps.length);
        setCooking(recipeCurrentSteps, true);
        if (navigator.onLine && progressFetcher.state === "idle" && rootData.hasSub) {
          const form = new FormData();
          form.set("recipes", JSON.stringify(recipeCurrentSteps));
          form.set("action", "addProgress");
          progressFetcher.submit(form, {
            method: "POST",
            action: "/api/recipe/progress",
          });
        }
      } else {
        deleteCooking();
      }
    }
    setInitialized(true);
  }, [recipeCurrentSteps]);

  const updateProgressDb = (type: string, uuid: string) => {
    if (navigator.onLine && rootData.hasSub) {
      const form = new FormData();
      form.set("uuid", uuid);
      form.set("action", type);
      progressFetcher.submit(form, {
        method: "POST",
        action: "/api/recipe/progress",
      });
    }
  };

  const completeRecipeDb = (uuid: string) => {
    updateProgressDb("completeRecipe", uuid);
  };

  const cancelRecipeDb = (uuid: string) => {
    updateProgressDb("cancelRecipe", uuid);
  };

  useEffect(() => {
    if (initialized) {
      if (currentRecipeId) {
        setCurrentCooking(currentRecipeId);
      } else {
        deleteCurrentCooking();
      }
    }
    setInitialized(true);
  }, [currentRecipeId]);

  const setIngredientsCheckedUuid = useCallback(
    (uuid: string, ids: string[]) => {
      const existingChecked = { ...ingredientsChecked };
      existingChecked[uuid] = ids;
      setIngredientsChecked(existingChecked);
    },
    [ingredientsChecked]
  );

  //write an effect to watch for changes to the current step and update the recipeCurrentSteps

  const setIngredientsPreppedUuid = useCallback(
    (uuid: string, ids: string[]) => {
      const existingPrepped = { ...ingredientsPrepped };
      existingPrepped[uuid] = ids;
      setIngredientsPrepped(existingPrepped);
    },
    [ingredientsPrepped]
  );

  const addIngredientChecked = useCallback(
    (id: string, uuid: string) => {
      const existingChecked = { ...ingredientsChecked };
      if (existingChecked[uuid]) {
        existingChecked[uuid].push(id);
      } else {
        existingChecked[uuid] = [id];
      }
      setIngredientsChecked(existingChecked);
    },
    [ingredientsChecked]
  );
  const removeIngredientChecked = useCallback(
    (id: string, uuid: string) => {
      const existingChecked = { ...ingredientsChecked };
      if (existingChecked[uuid]) {
        existingChecked[uuid] = existingChecked[uuid].filter((ingId) => id !== ingId);
      }
      setIngredientsPrepped(existingChecked);
    },
    [ingredientsChecked]
  );

  const addIngredientPrepped = useCallback(
    (id: string, uuid: string) => {
      const existingPrepped = { ...ingredientsPrepped };
      if (existingPrepped[uuid]) {
        existingPrepped[uuid].push(id);
      } else {
        existingPrepped[uuid] = [id];
      }
      setIngredientsPrepped(existingPrepped);
    },
    [ingredientsPrepped]
  );

  const removeIngredientPrepped = useCallback(
    (id: string, uuid: string) => {
      const existingPrepped = { ...ingredientsPrepped };
      if (existingPrepped[uuid]) {
        existingPrepped[uuid] = existingPrepped[uuid].filter((ingId) => id !== ingId);
      }
      setIngredientsPrepped(existingPrepped);
    },
    [ingredientsPrepped]
  );

  const clearRecipe = (uuid: string) => {
    // const currentRecipe = recipeCurrentSteps.find((r) => r.uuid === uuid);
    setRecipeCurrentSteps(rCSRef.current.filter((s) => s.uuid !== uuid));
    //TODO: need to clean the recipe if it is the only one
    // removeRecipe(currentRecipe.recipeId);
  };

  const openCookRecipe = useCallback(() => {
    setOpen(true);
    openSide("cook");
  }, [location.pathname, location.search]);

  const cookRecipeFinal = useCallback(
    (newRecipe: any, dateId?: string) => {
      const currentStep = 0;
      const recipeUid = uuid();
      setRecipeCurrentSteps([
        ...recipeCurrentSteps,
        {
          recipeId: newRecipe.id,
          currentStep,
          uuid: recipeUid,
          updatedAt: new Date(),
          createdAt: new Date(),
          recipeDateId: dateId,
          servings: newRecipe.recipeYield?.split(" ")[0] ?? 1,
        },
      ]);
      addRecipe(newRecipe);
      setCurrentRecipeId(recipeUid);
      openCookRecipe();
    },
    [recipeCurrentSteps, currentRecipeId, recipes]
  );

  const cookRecipe = useCallback((newRecipe: any, dateId?: string) => {
    // console.log("newRecipe: ", newRecipe.id);
    // console.log("dateId: ", dateId);
    //check if the recipe is already being cooked
    const alreadyStarted = rCSRef.current.filter((r) => r.recipeId === newRecipe.id);
    if (alreadyStarted.length > 0) {
      //if there is only one open, go ahead and open it?
      openChooseCook();
    } else {
      cookRecipeFinal(newRecipe, dateId);
    }
  }, []);

  const openExistingRecipe = useCallback((uuid: string) => {
    setCurrentRecipeId(uuid);
    openCookRecipe();
    // closeChooseCook();
  }, []);

  const completeRecipe = useCallback(
    (uuid: string) => {
      //TODO: save completion in db
      completeRecipeDb(uuid);
      clearRecipe(uuid);
      closeCookRecipe();
    },
    [recipeCurrentSteps]
  );

  const cancelRecipe = useCallback(
    (uuid: string) => {
      console.log("uuid: ", uuid);
      cancelRecipeDb(uuid);
      clearRecipe(uuid);
      closeCookRecipe();
    },
    [recipeCurrentSteps]
  );

  const closeCookRecipe = useCallback(() => {
    setOpen(false);
    closeSide("cook");
  }, [location.search, location.pathname]);

  const openChooseCook = () => {
    setChooseOpen(true);
    openSide("choose");
  };

  const closeChooseCook = () => {
    setChooseOpen(false);
    setThisRecipeId(null);
    closeSide("choose");
  };

  const nextStep = useCallback(
    (recipeId) => {
      //TODO: save progress in db
      const recipe = recipes.find((r) => r.id === recipeId);
      const currentStep = recipeCurrentSteps.find((s) => s.recipeId === recipeId)?.currentStep;
      setRecipeCurrentSteps((prev) =>
        prev.map((s) => (s.recipeId === recipeId ? { ...s, currentStep: Math.min(currentStep + 1, recipe?.steps.length - 1), updatedAt: new Date() } : s))
      );
      // setCurrentStep(Math.min(recipe?.steps.length - 1, currentStep + 1));
    },
    [recipes]
  );

  const prevStep = useCallback(
    (recipeId) => {
      const currentStep = recipeCurrentSteps.find((s) => s.recipeId === recipeId)?.currentStep;
      setRecipeCurrentSteps((prev) =>
        prev.map((s) => (s.recipeId === recipeId ? { ...s, currentStep: Math.max(currentStep - 1, 0), updatedAt: new Date() } : s))
      );
      // setCurrentStep(Math.max(currentStep - 1, 0));
    },
    [recipes]
  );

  const openIngredients = () => {
    setIngredientsOpen(true);
    openSide("ingredients");
  };
  const closeIngredients = () => {
    setIngredientsOpen(false);
    closeSide("ingredients");
  };

  const setRecipeStep = useCallback(
    (recipeId, step) => {
      setRecipeCurrentSteps((prev) => prev.map((s) => (s.recipeId === recipeId ? { ...s, currentStep: step, updatedAt: new Date() } : s)));
    },
    [recipes]
  );

  const recipe = useMemo(() => {
    const currentRecipe = recipeCurrentSteps?.find((s) => s.uuid === currentRecipeId);
    const rec = recipes.find((r) => r.id === currentRecipe?.recipeId) ?? {};
    return { ...rec, currentStep: currentRecipe?.currentStep, uuid: currentRecipe?.uuid };
  }, [recipes, currentRecipeId, recipeCurrentSteps]);

  const currentRecipeStep = useMemo(() => {
    const currentRecipe = recipeCurrentSteps?.find((s) => s.uuid === currentRecipeId);
    return currentRecipe;
  }, [recipe, recipeCurrentSteps, currentRecipeId]);

  useEffect(() => {
    if (currentRecipeStep?.servings) {
      setCurrentRecipeServings(parseInt(currentRecipeStep.servings));
    } else if (recipe?.recipeYield) {
      const servings = recipe.recipeYield?.split(" ") ?? [1];
      setCurrentRecipeServings(parseInt(servings[0]));
    } else {
      setCurrentRecipeServings(1);
    }
  }, [recipe.recipeYield, currentRecipeStep?.servings]);

  const preppedIngs = useMemo(() => {
    const currentRecipe = recipeCurrentSteps?.find((s) => s.uuid === currentRecipeId);
    return ingredientsPrepped[currentRecipe?.uuid] ?? [];
  }, [ingredientsPrepped, currentRecipeId, recipeCurrentSteps]);

  const cookedIngs = useMemo(() => {
    const currentRecipe = recipeCurrentSteps?.find((s) => s.uuid === currentRecipeId);
    return ingredientsChecked[currentRecipe?.uuid] ?? [];
  }, [ingredientsChecked, currentRecipeId, recipeCurrentSteps]);

  const currentStep = useMemo(() => {
    const current = recipeCurrentSteps?.find((r) => r.uuid === currentRecipeId);
    return current?.currentStep || null;
  }, [recipe, recipeCurrentSteps, currentRecipeId]);

  useEffect(() => {
    //if current step >0 then default the tab to cooking else default prep
    if (currentStep > 0) {
      setIngredientsTab("cooking");
    } else {
      setIngredientsTab("prepping");
    }
  }, [currentStep]);

  const clearCookingContext = () => {
    setRecipeCurrentSteps([]);
    setCurrentRecipeId(null);
    setRecipes([]);
    setIngredientsPrepped({});
    setIngredientsChecked({});
    deleteCooking();
    deleteCurrentCooking();
  };

  const thisRecipe = useMemo(() => {
    return recipes.find((r) => r.id === thisRecipeId);
  }, [recipes, thisRecipeId]);
  return {
    open,
    ingredientsOpen,
    openCookRecipe,
    closeCookRecipe,
    clearRecipe,
    cookRecipe,
    cookRecipeFinal,
    recipes,
    currentRecipeId,
    nextStep,
    prevStep,
    recipeCurrentSteps,
    numRecipes: recipeWithStep?.length,
    setCurrentStep: setRecipeStep,
    completeRecipe,
    cancelRecipe,
    openIngredients,
    closeIngredients,
    ingredientsTab,
    setIngredientsTab,
    ingredientsChecked: cookedIngs,
    setIngredientsChecked: setIngredientsCheckedUuid,
    removeIngredientChecked,
    addIngredientChecked,
    ingredientsPrepped: preppedIngs,
    setIngredientsPrepped: setIngredientsPreppedUuid,
    addIngredientPrepped,
    removeIngredientPrepped,
    chooseOpen,
    openChooseCook,
    closeChooseCook,
    openExistingRecipe,
    recipe,
    currentStep,
    clearCookingContext,
    thisRecipe,
    setThisRecipeId,
    replaceRecipe,
    currentRecipeServings,
    setCurrentRecipeServings,
    currentServingRatio,
    thisCollectionId,
    setThisCollectionId,
    lookupRecipes,
  };
};

const CookRecipeContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const cookRecipeContext = useCookRecipeContextVals();

  return <CookRecipeContext.Provider value={{ ...cookRecipeContext }}>{props.children}</CookRecipeContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useCookRecipeContext = () => useContext(CookRecipeContext);
const useCookRecipeSelectorContext = (selector: any) => {
  return useContextSelector(CookRecipeContext, selector);
};

export { CookRecipeContextProvider, useCookRecipeContext, useCookRecipeSelectorContext };
