import { createClient } from "@supabase/supabase-js";

const isServer = typeof window === "undefined";
const supabaseUrl = isServer ? process.env.SUPABASE_API_URL : window?.ENV.SUPABASE_API_URL;
const supabaseKey = isServer ? process.env.SUPABASE_ANON_PUBLIC_KEY : window?.ENV.SUPABASE_KEY?.toString() ?? "";

export default createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: false,
  },
  realtime: {
    log_level: 'info',
  },
});
