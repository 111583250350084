import { useLocation, useSearchParams } from "@remix-run/react";
import { useCallback, useMemo, useRef, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { useDidMountEffect } from "~/utils/misc";

const PopoverContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const usePopoverContextVals = () => {
  const locationRef = useRef();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [sidesOpen, setSidesOpen] = useState(searchParams.get("m")?.split(",") || []);

  locationRef.current = location;

  // create a useEffect that will monitor the URL for search param changes
  // and update the sidesOpen state accordingly
  useDidMountEffect(() => {
    const sides = searchParams.get("m");
    if (sides) {
      setSidesOpen(sides.split(","));
    }
  }, [searchParams]);

  const memoizedSides = useMemo(() => JSON.stringify(sidesOpen.sort()), [sidesOpen]);

  const openSide = useCallback(
    (side) => {
      if (!sidesOpen.includes(side)) {
        setSidesOpen([...sidesOpen, side]);
      }
    },
    [sidesOpen]
  );

  const closeSide = useCallback(
    (side) => {
      setSidesOpen(sidesOpen.filter((s) => s !== side));
    },
    [sidesOpen]
  );

  // useDidMountEffect(() => {
  //   console.log("location changed", location);
  // }, [location]);

  useDidMountEffect(() => {
    const cloneParams = new URLSearchParams(searchParams.toString());
    if (sidesOpen.length === 0) {
      searchParams.delete("m");
    } else {
      searchParams.set("m", [...sidesOpen].join(","));
    }
    if (cloneParams.toString() !== searchParams.toString()) {
      // console.log("setting search params", searchParams.toString());
      // setSearchParams(searchParams, { replace: false });
      setSearchParams(searchParams, { replace: true });
    }
  }, [memoizedSides]);

  return {
    openSide,
    closeSide,
    sidesOpen,
  };
};

const PopoverContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const popoverContext = usePopoverContextVals();

  return <PopoverContext.Provider value={{ ...popoverContext }}>{props.children}</PopoverContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const usePopoverContext = () => useContext(PopoverContext);
const usePopoverSelectorContext = (selector: any) => {
  return useContextSelector(PopoverContext, selector);
};
export { PopoverContextProvider, usePopoverContext, usePopoverSelectorContext };
