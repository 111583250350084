import { useFetcher, useFetchers } from "@remix-run/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { useRootData } from "~/utils/data/useRootData";
import useLocalStorage from "~/utils/hooks/use-local-storage";
import supabase from "~/utils/integrations/supabaseClientService";
import { useCookRecipeSelectorContext } from "./cookRecipeContext";

const ForkedRecipeContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useForkedRecipeContextVals = () => {
  const rootData = useRootData();
  const [localUserRecipes, setLocalUserRecipes] = useLocalStorage("userRecipes", []);
  const [userRecipes, setUserRecipes] = React.useState(localUserRecipes);
  const [freeRecipes, setFreeRecipes] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("recipe");
  const [activeEntity, setActiveEntity] = React.useState("recipe");
  const [forkedCollectionIds, setForkedCollectionIds] = React.useState([]);
  const [forkedRecipeIds, setForkedRecipeIds] = React.useState([]);
  const forkedRecipeRef = useRef();
  forkedRecipeRef.current = userRecipes?.map((r) => r.recipeId) ?? [];

  // const forkedRecipeIds = useMemo(() => {
  //   return userRecipes.map((r) => r.recipeId);
  // }, [userRecipes]);

  //create a state to store the users Collections
  const [userCollections, setUserCollections] = React.useState([]);
  //create a state to store the users Meals
  const [userMeals, setUserMeals] = React.useState([]);
  const [mealType, setMealType] = React.useState(null);
  const [newCollectionOpen, setNewCollectionOpen] = React.useState(false);
  const [addCollectionOpen, setAddCollectionOpen] = React.useState(false);

  const collectionIds = useMemo(() => {
    return [...userCollections.map((c) => c.id), ...userMeals.map((m) => m.id)];
  }, [userCollections, userMeals]);

  const userRecipeFetcher = useFetcher();
  const collectionFetcher = useFetcher();
  const freeRecipeFetcher = useFetcher();
  const fetchers = useFetchers();
  const recipes = useCookRecipeSelectorContext((state) => state.recipes);
  const thisCollectionId = useCookRecipeSelectorContext((state) => state.thisCollectionId);
  const [hasAsked, setHasAsked] = React.useState(false);
  const subscriptionRef = useRef();
  const subscription2Ref = useRef();
  const forkedRecipeSub = useRef();

  const processForkChange = (data) => {
    getForkedRecipes();
  };

  const processForkRecipeChange = (data) => {
    getForkedRecipes();
  };

  const updateCollectionData = (collectionId: string) => {
    const form = new FormData();
    form.set("action", "getDetailedCollection");
    form.set("collectionId", collectionId);
    collectionFetcher.submit(form, {
      action: "/api/recipe/collection",
      method: "post",
    });
  };

  //create an effect to watch for collectionFetcher data changes
  useEffect(() => {
    if (collectionFetcher.data) {
      const collection = collectionFetcher.data.replaceCollection;
      if (collection) {
        setUserCollections((prev) => {
          const existing = prev.find((c) => c.id === collection.id) ?? {};
          const newCollections = prev.filter((c) => c.id !== collection.id);
          return [...newCollections, { ...existing, ...collection }];
        });
      }
    }
  }, [collectionFetcher.data]);

  const checkCollectionChanges = useCallback(
    (collectionId) => {
      if (collectionId === thisCollectionId) {
        updateCollectionData(collectionId);
      }
    },
    [thisCollectionId]
  );

  const processCollectionChange = useCallback(
    (data) => {
      const collection = data.new;
      if (collection) {
        if (mealType && collection.type === mealType) {
          //need to add to meals
          setUserMeals((prev) => {
            const existing = prev.find((c) => c.id === collection.id) ?? {};
            const newCollections = prev.filter((c) => c.id !== collection.id);
            checkCollectionChanges(collection.id);
            return [...newCollections, { ...existing, ...collection, public: collection.public ?? false }];
          });
        } else {
          setUserCollections((prev) => {
            const existing = prev.find((c) => c.id === collection.id) ?? {};
            const newCollections = prev.filter((c) => c.id !== collection.id);
            checkCollectionChanges(collection.id);
            return [...newCollections, { ...existing, ...collection, public: collection.public ?? false }];
          });
        }
      }
    },
    [mealType]
  );

  useEffect(() => {
    if (rootData?.userSession?.userId && !rootData.hasSub) {
      const form = new FormData();
      form.set("action", "getFreeRecipes");

      freeRecipeFetcher.submit(form, {
        action: "/api/recipe/fork",
        method: "POST",
      });
    }
  }, [rootData?.userSession?.userId, rootData.hasSub]);

  const handleAddFreeRecipe = useCallback((id) => {
    const form = new FormData();
    form.set("action", "addFreeRecipe");
    form.set("recipeId", id);

    freeRecipeFetcher.submit(form, {
      action: "/api/recipe/fork",
      method: "POST",
    });
  }, []);

  useEffect(() => {
    if (forkedRecipeSub.current) {
      forkedRecipeSub.current.unsubscribe();
    }
    if (rootData?.userSession?.userId) {
      forkedRecipeSub.current = supabase
        .channel(`forked-recipes`)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "UserRecipe",
            filter: `userId=eq.${rootData?.userSession?.userId}`,
          },
          processForkRecipeChange
        )
        .subscribe();
    }
  }, [rootData?.userSession?.userId]);

  useEffect(() => {
    if (subscription2Ref.current) {
      subscription2Ref.current.unsubscribe();
    }

    if (collectionIds.length > 0) {
      // subscription2Ref.current = supabase
      //   .channel(`recipe-collections`)
      //   .on(
      //     "postgres_changes",
      //     {
      //       event: "*",
      //       schema: "public",
      //       table: "RecipeCollection",
      //       filter: `id=in.(${collectionIds.join(", ")})`,
      //     },
      //     processCollectionChange
      //   )
      //   .subscribe();
    }
  }, [collectionIds]);

  const replaceCollection = useCallback(
    (collection) => {
      setUserCollections((prev) => {
        const newCollections = prev.filter((c) => c.id !== collection.id);
        return [...newCollections, collection];
      });
    },
    [userCollections]
  );

  const openNewCollection = () => {
    setNewCollectionOpen(true);
  };

  const closeNewCollection = () => {
    setNewCollectionOpen(false);
  };

  const addRecipe = useCallback(
    (recipeId) => {
      // if recipeId is not in userRecipes, add it

      const rec = recipes.find((r) => r.recipeId === recipeId);
      if (rec) {
        if (!localUserRecipes.find((r) => r.recipeId === recipeId)) {
          setLocalUserRecipes([...localUserRecipes, rec]);
        }
        if (!userRecipes.find((r) => r.recipeId === recipeId)) {
          setUserRecipes([...userRecipes, rec]);
        }
      }
    },
    [recipes, localUserRecipes, userRecipes]
  );

  const removeRecipe = useCallback(
    (recipeId) => {
      setLocalUserRecipes(localUserRecipes.filter((recipe) => recipe.recipeId !== recipeId));
    },
    [localUserRecipes]
  );

  useEffect(() => {
    const forking = fetchers.find((fetcher) => fetcher.formAction === "/api/recipe/fork" && fetcher.formMethod === "POST");
    if (forking) {
      const data = Object.fromEntries(forking.formData);
      if (data.action === "unfork-recipe") {
        removeRecipe(data.recipeId);
        setForkedRecipeIds(forkedRecipeIds.filter((id) => id !== data.recipeId));
      } else if (data.action === "unfork-collection") {
        setForkedCollectionIds(forkedCollectionIds.filter((id) => id !== data.recipeId));
      } else if (data.action === "fork-collection") {
        setForkedCollectionIds([...forkedCollectionIds, data.recipeId]);
      } else if (data.action === "fork-recipe") {
        // getFOrkedRecipeIds and make sure to add this recipe id if it's not there
        const uniqueIds = [...new Set([...forkedRecipeIds, data.recipeId])];
        setForkedRecipeIds(uniqueIds);
        addRecipe(data.recipeId);
      }
      //TODO: add forking recipe, grab from context
    }
  }, [fetchers]);

  //write an effect to watch for changes to the user's recipes
  useEffect(() => {
    if (userRecipeFetcher.data) {
      setLocalUserRecipes(
        userRecipeFetcher.data.allUserRecipes
          ? userRecipeFetcher.data.allUserRecipes.map((r) => {
              return {
                recipeId: r.recipeId,
                name: r.name,
                imageFilename: r.imageFilename,
                urlId: r.urlId,
                urlKey: r.urlKey,
                url: r.url,
              };
            })
          : []
      );

      let mealTypeId = mealType;
      if (userRecipeFetcher.data.mealType) {
        mealTypeId = userRecipeFetcher.data.mealType?.id;
        setMealType(mealTypeId);
      }
      setForkedRecipeIds(userRecipeFetcher.data.allUserRecipes?.map((r) => r.recipeId) ?? []);
      setForkedCollectionIds(userRecipeFetcher.data.allUserCollections?.filter((c) => c.forked).map((r) => r.id) ?? []);
      setUserCollections((prev) => {
        const newIds = userRecipeFetcher.data.allUserCollections?.map((c) => c.id);
        const oldIds = prev.map((c) => c.id);
        const toAdd = userRecipeFetcher.data.allUserCollections?.filter((c) => !oldIds.includes(c.id)) ?? [];
        return [
          ...prev.map((c) => {
            if (newIds.includes(c.id)) {
              const found = userRecipeFetcher.data.allUserCollections?.find((c2) => c2.id === c.id);
              return {
                ...c,
                ...found,
                imageFilename: c.imageFilename ?? found?.imageFilename,
              };
            } else {
              return c;
            }
          }),
          ...toAdd,
        ].filter((c) => {
          if (mealTypeId) {
            return c.type !== mealTypeId;
          } else {
            return true;
          }
        });
      });
      setUserMeals((prev) => {
        const newIds = userRecipeFetcher.data.allUserCollections?.map((c) => c.id);
        const oldIds = prev.map((c) => c.id);
        const toAdd = userRecipeFetcher.data.allUserCollections?.filter((c) => !oldIds.includes(c.id)) ?? [];
        return [
          ...prev.map((c) => {
            if (newIds.includes(c.id)) {
              const found = userRecipeFetcher.data.allUserCollections?.find((c2) => c2.id === c.id);
              return {
                ...c,
                ...found,
              };
            } else {
              return c;
            }
          }),
          ...toAdd,
        ].filter((c) => {
          if (mealTypeId) {
            return c.type === mealTypeId;
          } else {
            return false;
          }
        });
      });
      setHasAsked(true);
    }
  }, [userRecipeFetcher.data]);

  useEffect(() => {
    if (freeRecipeFetcher.data) {
      setFreeRecipes(freeRecipeFetcher.data.freeRecipes);
      setHasAsked(true);
    }
  }, [freeRecipeFetcher.data]);

  const getForkedRecipes = () => {
    const form = new FormData();
    form.set("action", "getForks");
    console.log("userRecipeFetcher submit");
    userRecipeFetcher.submit(form, {
      action: "/api/recipe/fork",
      method: "POST",
    });
  };

  useEffect(() => {
    //go get the user's recipes
    if (rootData.hasSub) {
      getForkedRecipes();
    }
  }, [rootData.hasSub]);

  const openAddCollection = () => {
    setAddCollectionOpen(true);
  };

  const closeAddCollection = () => {
    setAddCollectionOpen(false);
  };

  const cleanupForkedContext = () => {
    setUserCollections([]);
    setUserMeals([]);
    setLocalUserRecipes([]);
    setUserRecipes([]);
  };

  const userForkedCollections = useMemo(() => {
    return userCollections.filter((c) => forkedCollectionIds.includes(c.id));
  }, [userCollections, forkedCollectionIds]);

  return {
    userRecipes: localUserRecipes,
    userCollections,
    userForkedCollections,
    userMeals,
    newCollectionOpen,
    openNewCollection,
    closeNewCollection,
    activeTab,
    setActiveTab,
    addCollectionOpen,
    openAddCollection,
    closeAddCollection,
    activeEntity,
    setActiveEntity,
    replaceCollection,
    cleanupForkedContext,
    mealType,
    updateCollectionData,
    collectionIds,
    forkedRecipeIds,
    forkedCollectionIds,
    freeRecipes,
    handleAddFreeRecipe,
    hasAsked,
  };
};

const ForkedRecipeContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const forkedrecipeContext = useForkedRecipeContextVals();

  return <ForkedRecipeContext.Provider value={{ ...forkedrecipeContext }}>{props.children}</ForkedRecipeContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useForkedRecipeContext = () => useContext(ForkedRecipeContext);
const useForkedRecipeSelectorContext = (selector: any) => {
  return useContextSelector(ForkedRecipeContext, selector);
};
export { ForkedRecipeContextProvider, useForkedRecipeContext, useForkedRecipeSelectorContext };
